import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchElements(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/billing/consecutive_invoice', { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchElement(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/billing/consecutive_invoice/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addElement(ctx, elementData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/billing/consecutive_invoice`, elementData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editElement(ctx, elementData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/billing/consecutive_invoice/${elementData.id}`, elementData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteElement(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/billing/consecutive_invoice/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
