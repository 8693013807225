<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_data') }}
      </h4>
    </b-alert>
    <template v-if="elementData">
      <b-card>
        <b-card-header>
          <div class="text-primary h2 col-12">
            <b-row>
              <!-- Right Col: Table -->
              <b-col
                cols="9"
                xl="9"
              >
                <span class="font-weight-bold">
                  {{ $t(RESOURCES_ELEMENT_NAME + (newElement ? ".section.create" : ".section.edit")) }}
                </span>
              </b-col>
            </b-row>
            <br>
          </div>
        </b-card-header>
        <b-card-body>
          <validation-observer
            ref="elementForm"
            #default="{ invalid }"
          >
            <b-form
              @submit.prevent="save"
            >
              <b-row>
                <b-col
                  cols="4"
                >
                  <b-form-group
                    label-for="prefix_id"
                  >
                    <span class="font-weight-bold">{{ $t(RESOURCES_ELEMENT_NAME + ".object.prefix") }}</span><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="prefix"
                      rules="required"
                    >
                      <b-form-input
                        v-model="elementData.prefix"
                        maxlength="4"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="4"
                >
                  <b-form-group
                    label-for="valid_since"
                  >
                    <date-picker
                      ref="valid_since_id"
                      :element-required="true"
                      element-field="valid_since"
                      :element-label="$t(RESOURCES_ELEMENT_NAME + '.object.valid_since')"
                      :root-element-data="elementData"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="4"
                >
                  <b-form-group
                    label-for="valid_until"
                  >
                    <date-picker
                      ref="valid_until_id"
                      :element-required="true"
                      element-field="valid_until"
                      :element-label="$t(RESOURCES_ELEMENT_NAME + '.object.valid_until')"
                      :root-element-data="elementData"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="4"
                >
                  <b-form-group
                    label-for="start_number_id"
                  >
                    <span class="font-weight-bold">{{ $t(RESOURCES_ELEMENT_NAME + ".object.start_number") }}</span><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="start_number"
                      rules="required"
                    >
                      <b-form-input
                        v-model="elementData.start_number"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="4"
                >
                  <b-form-group
                    label-for="end_number_id"
                  >
                    <span class="font-weight-bold">{{ $t(RESOURCES_ELEMENT_NAME + ".object.end_number") }}</span><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="end_number"
                      rules="required"
                    >
                      <b-form-input
                        v-model="elementData.end_number"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="4"
                >
                  <b-form-group
                    label-for="next_number_id"
                  >
                    <span class="font-weight-bold">{{ $t(RESOURCES_ELEMENT_NAME + ".object.next_number") }}</span><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="next_number"
                      rules="required"
                    >
                      <b-form-input
                        v-model="elementData.next_number"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>

              <br>

              <b-row class="d-flex justify-content-end">
                <b-button
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  @click="router().go(-1)"
                >
                  {{ $t('actions.back') }}
                </b-button>

                <b-button
                  variant="primary"
                  type="submit"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :disabled="invalid"
                >
                  {{ $t('actions.save') }}
                </b-button>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-body>
      </b-card>

    </template>

  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BAlert, BLink, BCard, BCardHeader, BCardBody, BFormInput, BFormGroup, BForm,
  BTooltip, BFormSelect,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'

import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import ElementStoreModule from '../storeModule'

export default {

  components: {
    BRow,
    BCol,
    BButton,
    BAlert,
    BLink,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BTooltip,
    BAlert,
    BLink,
    BAlert,
    BFormSelect,
    DatePicker,
  },
  data() {
    return {
      elementData: {
        valid_since: undefined,
        valid_until: undefined,
      },
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      arlList: [],
      paymentTermList: [],
      newElement: false,
      type: undefined,
      id: undefined,
    }
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'consecutiveInvoice'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-${this.RESOURCES_ELEMENT_NAME}`

    this.id = router.currentRoute.params.id

    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)

    if (this.id) {
      await this.fetchElement()
      this.newElement = false
    } else {
      this.elementData = {
      }
      this.newElement = true
    }
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    router() {
      return router
    },
    fetchElement() {
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchElement`, { id: this.id })
        .then(response => {
          this.elementData = response.data

          if (this.elementData.valid_since) {
            this.elementData.valid_since = moment(this.elementData.valid_since)
          }
          if (this.elementData.valid_until) {
            this.elementData.valid_until = moment(this.elementData.valid_until)
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.elementData)
            this.elementData = undefined
          }
        })
    },
    async save() {
      const method = this.ELEMENT_APP_STORE_MODULE_NAME + (this.newElement ? '/addElement' : '/editElement')
      const action = this.newElement ? 'creation' : 'update'
      const _self = this

      return store.dispatch(method, this.elementData).then(response => {
        _self.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.title`),
            icon: 'SaveIcon',
            variant: 'success',
            text: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.success`),
          },
        })
        _self.$router.replace({ name: 'apps-billing-consecutive-invoice' })
        return true
      }).catch(error => {
        console.info(error)
        _self.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.title`),
            icon: 'SaveIcon',
            variant: 'danger',
            text: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.failure`),
          },
        })
        return false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
